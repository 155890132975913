@tailwind base;
@tailwind components;
@tailwind utilities;

.hero-img {
    height: 100%;
}

.custom-height {
    height: 20%;
    width: auto;
}

.list-dash li::marker {
    content: "-";
    color: gray; 
}
